import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import colors from '../../styles/colors';
import setAuthToken from '../../utils/auth';

const Signup = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username === '' || email === '' || password === '') {
      setError('All fields are required');
      return;
    }

    try {
      const res = await axios.post('https://theofficeconnected.com/api/auth/signup', { username, email, password });
      setAuthToken(res.data.token);
      history.push('/');
    } catch (err) {
      setError('Error signing up');
    }
  };

  return (
    <div className="container">
      <h1 style={{ color: colors.primary }}>Sign Up</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ display: 'block', marginBottom: '10px', padding: '10px', width: '100%' }}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ display: 'block', marginBottom: '10px', padding: '10px', width: '100%' }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ display: 'block', marginBottom: '10px', padding: '10px', width: '100%' }}
        />
        <button type="submit" style={{ padding: '10px 20px', backgroundColor: colors.primary, color: '#fff', border: 'none', borderRadius: '5px' }}>
          Sign Up
        </button>
        {error && <p style={{ color: colors.error }}>{error}</p>}
      </form>
      <p>
        Already have an account? <a href="/">Login</a>
      </p>
    </div>
  );
};

export default Signup;
