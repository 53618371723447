import React, { useState } from 'react';
import axios from 'axios';
import colors from '../../styles/colors';

const Post = ({ history }) => {
  const [caption, setCaption] = useState('');
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('image', image);
    formData.append('caption', caption);

    try {
      const res = await axios.post('https://theofficeconnected.com/api/posts/create', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Post created successfully:', res.data); // Use the response
      history.push('/feed');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="container">
      <h1 style={{ color: colors.primary }}>Create Post</h1>
      <form onSubmit={handleSubmit}>
        <input type="file" onChange={handleImageChange} style={{ display: 'block', marginBottom: '10px' }} />
        <input
          type="text"
          placeholder="Caption"
          value={caption}
          onChange={(e) => setCaption(e.target.value)}
          style={{ display: 'block', marginBottom: '10px', padding: '10px', width: '100%' }}
        />
        <button type="submit" style={{ padding: '10px 20px', backgroundColor: colors.primary, color: '#fff', border: 'none', borderRadius: '5px' }}>
          Post
        </button>
      </form>
    </div>
  );
};

export default Post;
