import React, { useState, useEffect } from 'react';
import axios from 'axios';
import colors from '../../styles/colors';

const Profile = ({ match }) => {
  const [user, setUser] = useState(null);
  const [editing, setEditing] = useState(false);
  const [bio, setBio] = useState('');

  useEffect(() => {
    const fetchUser = async () => {
      const token = localStorage.getItem('token');
      const res = await axios.get(`https://theofficeconnected.com/api/users/${match.params.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUser(res.data);
      setBio(res.data.bio);
    };
    fetchUser();
  }, [match.params.id]);

  const saveBio = async () => {
    const token = localStorage.getItem('token');
    await axios.post(`https://theofficeconnected.com/api/users/${match.params.id}/updateBio`, { bio }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    setEditing(false);
  };

  if (!user) return <p>Loading...</p>;

  return (
    <div className="container">
      <h1 style={{ color: colors.primary }}>{user.username}</h1>
      <img src={user.profilePicture} alt="Profile" style={{ width: '100px', height: '100px', borderRadius: '50%', marginBottom: '10px' }} />
      {editing ? (
        <div>
          <input
            type="text"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            style={{ display: 'block', marginBottom: '10px', padding: '10px', width: '100%' }}
          />
          <button onClick={saveBio} style={{ padding: '10px 20px', backgroundColor: colors.primary, color: '#fff', border: 'none', borderRadius: '5px' }}>
            Save
          </button>
        </div>
      ) : (
        <p>{user.bio}</p>
      )}
      <button onClick={() => setEditing(!editing)} style={{ padding: '10px 20px', backgroundColor: colors.primary, color: '#fff', border: 'none', borderRadius: '5px' }}>
        {editing ? 'Cancel' : 'Edit Bio'}
      </button>
    </div>
  );
};

export default Profile;
