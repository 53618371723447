import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import socket from '../../services/socket';
import colors from '../../styles/colors';

const Feed = () => {
  const [posts, setPosts] = useState([]);

  const fetchPosts = useCallback(async () => {
    const token = localStorage.getItem('token');
    const res = await axios.get('https://theofficeconnected.com/api/posts', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    setPosts(res.data);
  }, []);

  useEffect(() => {
    fetchPosts();

    // Real-time updates
    socket.on('newPost', (newPost) => {
      setPosts((prevPosts) => [newPost, ...prevPosts]);
    });

    socket.on('updatePost', (updatedPost) => {
      setPosts((prevPosts) =>
        prevPosts.map((post) => (post._id === updatedPost._id ? updatedPost : post))
      );
    });

    return () => {
      socket.off('newPost');
      socket.off('updatePost');
    };
  }, [fetchPosts]);

  const likePost = async (postId) => {
    const token = localStorage.getItem('token');
    await axios.post('https://theofficeconnected.com/api/posts/like', { postId }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  };

  return (
    <div className="container">
      <h1 style={{ color: colors.primary }}>Feed</h1>
      {posts.map((post) => (
        <div key={post._id} style={{ backgroundColor: colors.surface, padding: '10px', marginBottom: '10px', borderRadius: '10px' }}>
          <img src={post.imageURL} alt="Post" style={{ width: '100%', borderRadius: '10px' }} />
          <p>{post.caption}</p>
          <p>Likes: {post.likes.length}</p>
          <button onClick={() => likePost(post._id)} style={{ padding: '5px 10px', backgroundColor: colors.primary, color: '#fff', border: 'none', borderRadius: '5px' }}>
            Like
          </button>
        </div>
      ))}
    </div>
  );
};

export default Feed;
