const colors = {
  primary: '#6200ee',
  accent: '#03dac4',
  background: '#f5f5f5',
  text: '#000000',
  textSecondary: '#757575',
  error: '#B00020',
  surface: '#ffffff',
};

export default colors;
