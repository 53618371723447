import React, { useState, useEffect } from 'react';
import axios from 'axios';
import colors from '../../styles/colors';

const Chat = ({ match }) => {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState('');

  useEffect(() => {
    const fetchMessages = async () => {
      const token = localStorage.getItem('token');
      const res = await axios.get(`https://theofficeconnected.com/api/chats/${match.params.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setMessages(res.data);
    };
    fetchMessages();
  }, [match.params.id]);

  const sendMessage = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    const res = await axios.post('https://theofficeconnected.com/api/chats/send', {
      chatId: match.params.id,
      senderId: localStorage.getItem('userId'), // Store userId in localStorage after login
      text
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    setMessages([...messages, res.data]);
    setText('');
  };

  return (
    <div className="container">
      <h1 style={{ color: colors.primary }}>Chat</h1>
      <div style={{ marginBottom: '20px' }}>
        {messages.map((message) => (
          <div key={message._id} style={{ backgroundColor: message.senderId === localStorage.getItem('userId') ? '#e1ffc7' : '#fff', padding: '10px', marginBottom: '5px', borderRadius: '5px' }}>
            <p>{message.text}</p>
          </div>
        ))}
      </div>
      <form onSubmit={sendMessage}>
        <input
          type="text"
          placeholder="Message"
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{ display: 'block', marginBottom: '10px', padding: '10px', width: '100%' }}
        />
        <button type="submit" style={{ padding: '10px 20px', backgroundColor: colors.primary, color: '#fff', border: 'none', borderRadius: '5px' }}>
          Send
        </button>
      </form>
    </div>
  );
};

export default Chat;
